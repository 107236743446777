import React, { useEffect, useRef, useState } from 'react';
//@ts-ignore
import * as styles from './jotform.module.css';
import JotformEmbed from 'react-jotform-embed';
import Modal from 'react-modal';
import axios from 'axios';
import CloseButton from '../Global/CloseButton';
export interface Props {
    formUrl: string;
    formStyle: 'embed' | 'popup';
    display: boolean;
    onPopUpClose?: () => void;
    params?: string;
}
import Cookies from 'js-cookie';

const Jotform: React.FC<Props> = ({
    formStyle = 'popup',
    formUrl,
    display,
    onPopUpClose = () => {},
    params,
}) => {
    let referrers = [
        'google',
        'facebook',
        'youtube',
        'bing',
        'yahoo',
        'duckduckgo',
    ];
    let doc = typeof document !== 'undefined' ? document : null;
    const cookie: any = doc
        ? doc.cookie.split('; ').reduce((prev: any, current: any) => {
              const [name, ...value] = current.split('=');
              prev[name] = value.join('=');
              return prev;
          }, {})
        : '';
    let [ipDetails, setIpDetails] = React.useState('');
    let [url, setUrl] = React.useState('');
    let ireferrer = cookie['_uc_referrer'];
    let lreferrer = cookie['_uc_last_referrer'];
    let ilandpage = cookie['_uc_initial_landing_page'];
    let visits = cookie['_uc_visits'];
    let ucontent = cookie['_uc_utm_content'];
    let uSource = cookie['_uc_utm_source'];
    let uMedium = cookie['_uc_utm_medium'];
    let uCampaign = cookie['_uc_utm_campaign'];
    const [promoCode, setPromocode] = useState('');
    //new fields
    let marketingChannel = '';
    let leadSource = '';
    let IReferrer = '';
    let referrer = '';
    if (uSource != '' && uSource !== undefined) {
        if (
            (typeof uMedium === 'string' || uMedium instanceof String) &&
            uMedium.toLowerCase() == 'partner'
        ) {
            let temp = uSource;
            uSource = uMedium;
            uMedium = temp;
        }
        marketingChannel = uSource;
        leadSource = uMedium;
    } else if (ireferrer != '' && ireferrer !== undefined) {
        IReferrer = ireferrer;
        referrer = ireferrer;
        referrers.forEach((ref) => {
            if (ref.includes(ireferrer)) {
                referrer = ref;
                return false; //returning false in foreach is == break
            }
        });
        marketingChannel = referrer;

        if (IReferrer == 'direct') {
            leadSource = 'Direct';
        } else {
            leadSource = 'organic';
        }
    }

    const getPathFromUrl = (stringUrl: string) => {
        return stringUrl.split(/[?#]/)[0];
    };

    const getUserGeolocationDetails = async () => {
        try {
            const resp = await axios.get(`${window.location.origin}/api`);
            setIpDetails(resp.data);
            const windowLocationSearch = window.location.search;
            const urlParams = new URLSearchParams(windowLocationSearch);
            const promo = urlParams.get('mbsy');

            if (promo) {
                setPromocode(promo);
            }
            marketingChannel =
                marketingChannel !== undefined
                    ? marketingChannel.substring(0, 40)
                    : marketingChannel;
            ireferrer =
                ireferrer !== undefined
                    ? ireferrer.substring(0, 255)
                    : ireferrer;
            lreferrer =
                lreferrer !== undefined
                    ? lreferrer.substring(0, 255)
                    : lreferrer;
            ilandpage =
                ilandpage !== undefined
                    ? ilandpage.substring(0, 255)
                    : ilandpage;
            leadSource =
                leadSource !== undefined
                    ? leadSource.substring(0, 37)
                    : leadSource;

            let swcookie = Cookies?.get('sw') ?? '';
            let cidCookie = Cookies?.get('_ga') ?? '';
            const partnerCookieKey = Cookies?.get('growSumoPartnerKey') ?? '';
            const promoCode = Cookies?.get('promocode') ?? '';
            const gclid = Cookies?.get('gclid') ?? '';
            const abtastykey =
                Cookies?.get('ABTasty')?.replace(/&/g, '$$') ?? '';
            var cookieValues =
                'USOURCE=' +
                uSource +
                '&leadSourceweb=' +
                leadSource +
                '&marketingChannel=' +
                marketingChannel +
                '&referrer=' +
                referrer +
                '&umedium=' +
                uMedium +
                '&ucampaign=' +
                uCampaign +
                '&ireferrer=' +
                ireferrer +
                '&lreferrer=' +
                lreferrer +
                '&ilandpage=' +
                ilandpage +
                '&visits=' +
                visits +
                '&ucontent=' +
                ucontent +
                '&ipAddress=' +
                resp.data.ip +
                '&swPage=' +
                getPathFromUrl(window.location.href) +
                '&swObject=' +
                swcookie +
                '&cid=' +
                cidCookie +
                '&partnerkey=' +
                partnerCookieKey +
                '&mbsy=' +
                (promoCode ||
                    cookie['_uc_utm_source'] ||
                    urlParams.get('utm_source') ||
                    '') +
                '&gclid=' +
                gclid +
                '&ABTasty=' +
                abtastykey;

            cookieValues = cookieValues.replace(/[^=&]+=(?:&|$)/g, '');
            if (formUrl.includes('?')) {
                var arr = formUrl.split('?');
                if (formUrl.length > 1 && arr[1] !== '') {
                    formUrl = formUrl + '&' + cookieValues;
                } else {
                    formUrl = formUrl + cookieValues;
                }
            } else {
                formUrl = formUrl + '?' + cookieValues;
            }
            setUrl(formUrl);
        } catch (e) {
            console.error(e);
        }
    };

    React.useEffect(() => {
        if (formUrl != '') {
            setTimeout(() => {
                getUserGeolocationDetails();
            }, 1000);
        }
    }, []);

    return (
        <>
            {ipDetails !== '' ? (
                <div>
                    {display === true ? (
                        formStyle == 'popup' ? (
                            <Modal
                                isOpen={display}
                                onRequestClose={() => onPopUpClose()}
                                shouldCloseOnOverlayClick={true}
                                className={styles.modal}
                                overlayClassName={styles.overlayJotForm}
                            >
                                <div>
                                    <div className={styles.closeButton}>
                                        <CloseButton
                                            visualStyle="orange"
                                            onClick={() => onPopUpClose()}
                                        />
                                    </div>

                                    <JotformEmbed src={url} />
                                </div>
                            </Modal>
                        ) : (
                            <JotformEmbed src={url} />
                        )
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default Jotform;
